import type { FC } from "react"
import { Button, HStack, Text, Icon } from "@chakra-ui/react"

import { EXTERNAL_ROUTES } from "common/constants/routes"
import Link from "common/components/Link"
import { useEFPStats } from "address/hooks/useEFPStats"
import { ArrowUpRight } from "ui/components/icons/font-awesome/ArrowUpRight"
import EFPIcon from "ui/components/icons/EFPImage"

type Props = {
  addressOrEns: string
}

const AddressEFPButton: FC<Props> = ({ addressOrEns }) => {
  const { data, isLoading, error } = useEFPStats(addressOrEns)

  if (error || isLoading || !data) return null

  const followersCount = parseInt(data.followers_count ?? "0") || 0
  const followingCount = parseInt(data.following_count ?? "0") || 0

  if (followersCount === 0 && followingCount === 0) return null

  const profileLink = EXTERNAL_ROUTES.efp.profile(addressOrEns)

  return (
    <Link
      isExternal
      aria-label={`View Ethereum Follow Protocol profile with ${followersCount.toLocaleString()} followers and ${followingCount.toLocaleString()} following`}
      href={profileLink}
    >
      <Button
        bg="white"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="8px"
        maxHeight="40px"
        px={2}
        py={2}
        width={{ base: "100%", md: "fit-content" }}
      >
        <HStack spacing={2}>
          <EFPIcon height="24px" width="24px" />
          <HStack spacing={1}>
            <Text color="gray.800" fontSize="sm" fontWeight="medium">
              {followingCount.toLocaleString()}
            </Text>
            <Text color="gray.500" fontSize="sm" fontWeight="normal">
              Following
            </Text>
          </HStack>
          <Text color="gray.600">·</Text>
          <HStack spacing={1}>
            <Text color="gray.800" fontSize="sm" fontWeight="medium">
              {followersCount.toLocaleString()}
            </Text>
            <Text color="gray.500" fontSize="sm" fontWeight="normal">
              Followers
            </Text>
          </HStack>

          <Icon as={ArrowUpRight} color="gray.600" h="16px" w="16px" />
        </HStack>
      </Button>
    </Link>
  )
}

export default AddressEFPButton
