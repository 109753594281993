import type { FC } from "react"
import type { CardProps, StackProps } from "@chakra-ui/react"
import { Stack } from "@chakra-ui/react"

import { useAddressEligibleFreeDelegation } from "delegation/hooks/useAddressEligibleFreeDelegation"
import DelegationEligibleMessage from "delegation/components/DelegationEligibleMessage"
import CardContainer from "common/components/CardContainer"
import type { Organization } from "query/graphql"
import { useMultichainAccount } from "web3/hooks/useMultichainAccount"
import { useAddress } from "web3/hooks/useAddress"

type Props = {
  organization: Organization
  tokenId?: string
  withCard?: boolean
}

const DelegationEligibleBanner: FC<Props & CardProps & StackProps> = ({
  organization,
  tokenId,
  withCard = false,
  ...stackProps
}) => {
  const address = useAddress()
  const { multichainAccount } = useMultichainAccount()

  const eligibleData = useAddressEligibleFreeDelegation({
    organizationId: organization.id,
    tokenId: tokenId ?? organization.tokenIds[0],
    address,
    accountType: multichainAccount?.type,
  })

  if (!eligibleData?.sponsorshipSupported) {
    return null
  }

  if (withCard) {
    return (
      <Stack {...stackProps}>
        <CardContainer pt={4}>
          <DelegationEligibleMessage
            displayLearnMore
            {...stackProps}
            addressEligible={eligibleData.addressEligible}
            freeDelegationsAvailable={eligibleData.freeDelegationsAvailable}
          />
        </CardContainer>
      </Stack>
    )
  }

  return (
    <DelegationEligibleMessage
      displayLearnMore
      {...stackProps}
      addressEligible={eligibleData.addressEligible}
      freeDelegationsAvailable={eligibleData.freeDelegationsAvailable}
    />
  )
}

export default DelegationEligibleBanner
