import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"

type DelegateDisabledModalProps = {
  isOpen: boolean
  onClose: () => void
}

const DelegateDisabledModal = ({
  isOpen,
  onClose,
}: DelegateDisabledModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent top={{ base: "unset", lg: "120px" }}>
        <ModalHeader
          borderBottomColor="gray.100"
          borderBottomWidth={1}
          p={4}
          textStyle="h5"
        >
          <Text textAlign="center">Delegate</Text>
          <ModalCloseButton right={4} top={4} />
        </ModalHeader>
        <ModalBody pb={4}>
          <Text>Delegation on Solana accounts is temporarily disabled</Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DelegateDisabledModal
