import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"

type DelegateWrongNetworkModalProps = {
  isOpen: boolean
  onClose: () => void
  network?: string
  text?: React.ReactElement
}

const DelegateWrongNetworkModal = ({
  isOpen,
  onClose,
  network,
  text,
}: DelegateWrongNetworkModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent top={{ base: "unset", lg: "120px" }}>
        <ModalHeader
          borderBottomColor="gray.100"
          borderBottomWidth={1}
          p={4}
          textStyle="h5"
        >
          <Text textAlign="center">Delegate</Text>
          <ModalCloseButton right={4} top={4} />
        </ModalHeader>
        <ModalBody pb={4}>
          {text ?? (
            <Text>
              This delegate is only available for delegations on {network}
            </Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DelegateWrongNetworkModal
