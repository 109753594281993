import { useQuery } from "@tanstack/react-query"

import { API_ROUTES } from "common/constants/routes"

type EFPStats = {
  followers_count: string
  following_count: string
}

export const useEFPStats = (addressOrEns: string) => {
  return useQuery<EFPStats>(
    ["efp-stats", addressOrEns],
    async () => {
      const response = await fetch(API_ROUTES.efp.stats(addressOrEns))

      if (!response.ok) {
        return null
      }

      return await response.json()
    },
    {
      enabled: Boolean(addressOrEns),
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
