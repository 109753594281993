import React from "react"
import { createIcon } from "@chakra-ui/react"

const EFPIcon = createIcon({
  displayName: "EFPIcon",
  viewBox: "0 0 24 24",
  path: (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="url(#paint0_linear_400_286)"
        height="24"
        width="24"
        x="0"
        y="0"
      />
      <path
        d="M8.19043 12.1198L12.3004 5.27979L16.3804 12.1198L12.3004 14.6098L8.19043 12.1198Z"
        fill="#333333"
      />
      <path
        d="M12.3004 15.3601L8.19043 12.8701L12.3004 18.6601L16.3804 12.8701L12.3004 15.3601Z"
        fill="#333333"
      />
      <path
        d="M17.5498 16.02H16.3798V17.76H14.7598V18.84H16.3798V20.64H17.5498V18.84H19.1398V17.76H17.5498V16.02Z"
        fill="#333333"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_400_286"
          x1="12.3301"
          x2="24.3301"
          y1="12"
          y2="24"
        >
          <stop stopColor="#FFE067" />
          <stop offset="1" stopColor="#FFF7D9" />
        </linearGradient>
      </defs>
    </svg>
  ),
})

export default EFPIcon
